<template>
    <div class="informationList">
        <div class="user_top">
            <div class="img">
                <img :src="userlist.headPortrait" alt="">
            </div>
            <div class="right_txt">
                <div class="username">
                    <div class="user_name">
                        {{ userlist.wechatNickname }}
                    </div>
                    <div class="icon_man">
                        <img src="../../../../static/images/jr-icon-man.png" v-if="userlist.gender == 2">
                        <img src="../../../../static/images/jr-icon-female.png" v-if="userlist.gender == 1">
                    </div>
                </div>
                <div class="phone">手机号: {{ userlist.mobile }}</div>
                <div class="date_user">
                    <span>会员有效期：{{ userlist.beginTime }} 至 {{ userlist.deadline }}</span>
                </div>
            </div>
        </div>
        <el-card style="margin: 16px 22px; border-radius: 12px">
            <div>
                <span class="information-tip">{{ type ? '编辑资讯' : '新增资讯' }}</span>
                <div class="information-line"></div>
            </div>
            <el-form :model="paramForm" :rules="formRules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="资讯名称" prop="informationName" class="information-name">
                    <el-input style="width: 450px" v-model="paramForm.informationName" placeholder="请输入资讯名称">
                    </el-input>
                </el-form-item>
                <el-form-item label="封面 " prop="coverImage">

                    <div style="color: #7c71e1; font-size: 12px; overflow: hidden;">
                        <upload-image-one :image='paramForm.coverImage' :imageShow="paramForm.coverImage"
                            :addTitleShow="false" @updateImgOne="updateImgOne" class="image" />

                    </div>
                </el-form-item>
                <el-form-item label="资讯描述" prop="informationDescribe"  >

                    <div class="editBar" >
                        <editorbar v-model="paramForm.informationDescribe" :isClear="isClear" @change="onchange" />
                    </div>
                    <div class="bottom_tip" style="color: #7c71e1; font-size: 12px;width: 868px;">
                        (图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)
                    </div>
                </el-form-item>

                <el-form-item label="关键词" prop="informationDescribes">

                    <div class="text" style="width:808px;height: 200px; border: 1px solid  #EFEFEF;
;"  >
                       <span v-for="(item,index) in particulars" :key="index">
                        <span>{{item}} , </span>
                       </span>
                    </div>
                </el-form-item>
                <el-form-item label="标签" prop="tag">
                    <el-input v-if="inputVisible" v-model="paramForm.tag" style="width:126px;" ref="saveTagInput"
                        size="small" clearable @keyup.enter.native="handleInputConfirm">
                    </el-input>
                    <div>
                        <i class="el-icon-circle-plus-outline" style="color:#7c71e1" />
                        <span @click="tagAdd" class="tag-add">添加</span>
                    </div>
                    <el-tag class="tag-style" :key="index" v-for="(tag, index) in paramForm.tags"
                        :disable-transitions="false" @close="handleClose(tag)" closable>
                        {{ tag }}
                    </el-tag>
                </el-form-item>

            </el-form>
            <div>
                <el-button type="primary" style="width: 136px" @click="saveNameCard">保存</el-button>
                <el-button style="width: 136px" @click="cancel" plain>取消</el-button>
            </div>
        </el-card>
    </div>
</template>
<script>
import { getUserDetail,saveNameCard ,selectParticulars} from '@/api/userManagement.js'
import UploadImageOne from '../../../components/UploadImageOne.vue'
import Editorbar from '../../../components/Editorbar.vue';
export default {
    name: "Newfamous",
    components: {
        UploadImageOne,
        Editorbar
    },
    data() {
        return {
            paramForm: {
                correctionMember: "",
                coverImage: "",
                id: 0,
                informationDescribe: '',
                informationName: "",
                informationResource: "",
                informationType: "",
               
                updateTime: "",
                
            },
            formRules: {
                informationName: [{ required: true, message: '请输入资讯名称', trigger: 'blur' }],
                coverImage: [{ required: true, message: '请上传资讯封面', trigger: 'blur' }],
                informationResource: [{ required: true, message: '请选择资讯来源', trigger: 'change' }],
                correctionMember: [{ required: true, message: '请选择关联成员', trigger: 'change' }],
                informationType: [{ required: true, message: '请选择资讯类型', trigger: 'change' }],
                tag: [{ required: true, message: '请选择资讯标签', trigger: 'change' }],
                informationDescribe: { required: true, message: '请输入资讯描述', trigger: 'blur' },
                 informationDescribes: { required: true, message: '请输入关键字', trigger: 'blur' }
            },
            options: [],
            inputVisible: false,
            dynamicTags: [],
            isClear: false,
            chargeWay: '',
            userlist: [],
            particulars:''

        }
    },
    computed: {
        id() {
            // console.log(this.$route.query.id);
            return this.$route.query.id * 1
        },
        type() {
            return this.$route.query.informationType * 1
        }
    },
    async created() {
        this.paramForm.tag = ""
        this.getUserDetail()
            
    },
    methods: {
        saveNameCard() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    if (this.type) {
                        const { data: res } = await saveNameCard(this.paramForm)
                        if (res.resultCode === 200) {
                            this.$message.success('发布成功！')
                            this.$router.go(-1)
                        } else {
                            this.$message.success('发布失败，请稍候重试')
                        }
                    }
                } else {
                    this.$message.warning('请完善基本信息')
                }
            })
        },
        async getUserDetail() {
            const { data: res } = await getUserDetail({ id: this.id })
            if (res.resultCode == 200) {
                this.userlist = res.data
            }

        },
        async selectParticulars(particulars){
          
             const {data:res}=await selectParticulars({particulars})
                if(res.resultCode==200){
                    this.particulars=res.data
                }
        },
       onchange(val){
    let replace = val.replace(/<[^>]+>/g,"");
         this.selectParticulars(replace)
        },
      
        updateImgOne(val) {
           
            this.paramForm.coverImage = val
        },
        //取消
        cancel() {
            this.$router.go(-1)
        },
        //动态添加标签
        tagAdd() {
            this.inputVisible = true
            this.paramForm.tag = ""

        },
        handleInputConfirm(e) {
            // console.log(e);

            let input = this.paramForm.tag;
            // console.log(input);
            // console.log(this.paramForm.tags);
            if (input) {
                this.paramForm.tags.push(input)
            }
            this.inputVisible = false;
            this.input = ''
        },
        //动态删除标签
        handleClose(tag) {
            this.paramForm.tags.splice(this.paramForm.tags.indexOf(tag), 1)
        },
    },
}

</script>
<style lang="less" scoped>
.informationList {
    width: 100%;

    .user_top {
        display: flex;
        height: 121px;
        background: #FFFFFF;
        margin: 16px 22px 0px 22px;

        .img {

            width: 80px;
            height: 90px;
            margin: 20px 0px 20px 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .right_txt {
            margin: 16px 22px 0px 22px;

            .username {
                display: flex;

                .user_name {
                    font-weight: 700;
                    color: #333333;
                }

                .icon_man {
                    width: 16px;
                    height: 14px;
                    margin-left: 6px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .phone {
                margin-top: 24px;
            }

            .date_user {
                margin-top: 8px;
            }
        }

    }

    .information-tip {
        font-size: 18px;
        color: #968de2;
    }

    .information-line {
        width: 100%;
        height: 0px;
        border: 1px solid #efefef;
        margin-top: 15px;
    }

    .information-name {
        margin-top: 10px;
    }

    .tag-add {
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        color: #7067F2;
    }

    .tag-style {
        background-color: #F1F0FE;
        color: #7067F2;
        border-color: #ffffff;
    }


    /deep/.w-e-text-container {
        z-index: 1 !important;
    }

    /deep/.w-e-menu {
        z-index: 2 !important;
    }

    /deep/.w-e-toolbar {
        z-index: 2 !important;
    }

    /deep/.el-button.is-plain:focus,
    .el-button.is-plain:hover {
        border-color: #7C71E1;
    }

    .editBar {
        width: 800px;
    }

}
</style>
